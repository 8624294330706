import { fetchBlogs } from "@methods/fetch";
import { getLocalStorage, setLocalStorage } from "@methods/localStorage";

window.pageMixins = [
  {
    data() {
      return {
        blogs: []
      }
    },
    created() {
      if (this.$root.isNewDataLoad() || !getLocalStorage('top-blogs')) {
        fetchBlogs((blogs) => {
          this.blogs = blogs;
          setLocalStorage('top-blogs', this.blogs);
        }, 20);
      } else {
        this.blogs = getLocalStorage('top-blogs');
      }
    },
  }
];
